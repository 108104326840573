<template>
    <div class="update-infos-container" v-if="!loading">
        <div class="top-block-wrapper">
            <VigikBreadCrumb />
            <h1 class="title">{{ $t("vigik.global.parameters") }}</h1>
            <p>* {{ $t("vigik.form.obligatoryFields") }}</p>
        </div>
        <div class="middle-block-wrapper">
            <div class="input-field" v-if="isVigik !== '1'">
                <p>{{ $t("vigik.form.name") }} *</p>
                <div class="input-name">
                    <div class="value-number">
                        <p>{{ name.length }} / 42</p>
                    </div>
                    <BasicInput
                        name="name"
                        :placeholder="$t('vigik.form.name')"
                        :rules="isNotEmpty"
                        v-model="name"
                        :maxlength="42"
                        type="text"
                    ></BasicInput>
                </div>
            </div>
            <div class="input-field" v-if="isVigik !== '1'">
                <p>{{ $t("vigik.form.description") }}</p>
                <div class="description-wrapper">
                    <div class="value-number">
                        <p>{{ description.length }} / 160</p>
                    </div>
                    <BasicInput
                        name="description"
                        class="text-area"
                        :resizeTextarea="false"
                        :maxlength="160"
                        :textarea="true"
                        :placeholder="$t('vigik.form.description')"
                        v-model="description"
                    ></BasicInput>
                </div>
            </div>
            <div class="input-field">
                <p>{{ $t("vigik.form.openingPriority") }} *</p>
                <BasicSelect
                    class="input-name"
                    name="openingPriority"
                    :options="openingPriorityOptions"
                    :needDefault="false"
                    v-model="openingPriority"
                />
            </div>
            <div class="button-choice">
                <BasicButton
                    @click="update"
                    :disabled="
                        isEqual(name, originalName) &&
                        isEqual(description, originalDescription) &&
                        isEqual(openingPriority, originalOpeningPriority)
                    "
                    >{{ $t("button.validate") }}</BasicButton
                >
                <BasicButton color="blue" @click="cancel">{{
                    $t("button.cancel")
                }}</BasicButton>
            </div>
        </div>
    </div>
    <div class="block-loader" v-else>
        <Loader />
    </div>
</template>

<script>
import { updateAccessType } from "@/services/intratone/accesstype"
import { getAccessTypeInfos } from "@/services/intratone/accesstype"
import { useForm } from "vee-validate"

import VigikBreadCrumb from "@/components/entities/VigikBreadCrumb.vue"
import BasicInput from "@/components/basic/BasicInput"
import BasicSelect from "@/components/basic/BasicSelect"
import BasicButton from "@/components/basic/BasicButton.vue"
import Loader from "@/components/basic/Loader"

export default {
    name: "UpdateInfosAccessType",
    components: {
        VigikBreadCrumb,
        BasicInput,
        BasicSelect,
        BasicButton,
        Loader,
    },
    data() {
        return {
            openingPriorityOptions: [
                {
                    value: "0",
                    name: "Fermé",
                },
                {
                    value: "1",
                    name: "Ouvert",
                },
            ],
            originalName: null,
            name: null,
            originalDescription: null,
            description: null,
            originalOpeningPriority: null,
            openingPriority: null,
            gestionnaireId: null,
            isVigik: null,
            loading: true,
        }
    },
    setup() {
        const { validate, errors } = useForm()

        return {
            validate,
            errors,
        }
    },
    async mounted() {
        await this.getData()
        this.updateBreadCrumb()
    },
    methods: {
        isNotEmpty(value) {
            if (value === "" || value === null || value === undefined) {
                return this.$t("input.empty")
            }
            return true
        },
        updateBreadCrumb() {
            this.$store.commit("breadCrumb/pushBreadCrumb", [
                {
                    name: this.$t("vigik.global.parameters"),
                    link: this.$route.path,
                    routeName: this.$route.name,
                },
            ])
        },

        async getData() {
            this.loading = true
            await getAccessTypeInfos(this.$route.params.id).then((response) => {
                this.originalName = response.data.name
                this.name = response.data.name

                this.originalDescription = response.data.description
                this.description = response.data.description

                this.originalOpeningPriority =
                    response.data.access_mode_priority
                this.openingPriority = response.data.access_mode_priority

                this.gestionnaireId = response.data.gestionnaire_id
                this.isVigik = response.data.vigik

                this.loading = false
            })
        },

        async update() {
            const validate = await this.validate()
            if (validate) {
                this.loading = true
                await updateAccessType(this.$route.params.id, {
                    id: this.$route.params.id,
                    name: this.name,
                    description: this.description,
                    access_mode_priority: this.openingPriority,
                }).then((response) => {
                    if (response.error == 0) {
                        this.$router.push({
                            name: "CustomAccessType",
                            params: {
                                id: this.$route.params.id,
                                vigikName: this.name,
                                gestionnaire_id: this.gestionnaireId,
                                isVigik: this.isVigik,
                            },
                        })
                    }
                })

                this.loading = false
            }
        },

        isEqual(value1, value2) {
            if (value1 === value2) {
                return true
            }
            return false
        },

        cancel() {
            this.$router.go(-1)
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";
.update-infos-container {
    padding: 20px 40px 0px 40px;
    display: flex;
    flex-direction: column;
    gap: 2em;
    overflow: auto;
    height: 100%;

    @media all and (max-width: 768px) {
        padding: 20px 20px 0px 20px;
    }
    .top-block-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
    }

    .middle-block-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1em;
        width: 100%;
        max-width: 800px;
        padding-bottom: 20px;
        @media all and (max-width: 768px) {
            width: 100%;
            min-width: 0px;
            max-width: 500px;
        }

        .input-field {
            display: flex;
            justify-content: space-between;
            gap: 2em;
            width: 100%;

            @media all and (max-width: 768px) {
                display: flex;
                flex-direction: column;
                gap: 1em;
            }

            .input-name {
                width: 70% !important;
                position: relative;

                @media all and (max-width: 480px) {
                    width: 100% !important;
                }

                .value-number {
                    font-size: $small;
                    position: absolute;
                    top: 1.2em;
                    right: 1em;
                }
            }

            .description-wrapper {
                height: 150px;
                width: 70%;
                position: relative;

                @media all and (max-width: 480px) {
                    width: 100% !important;
                }

                .text-area {
                    min-height: 150px;
                    height: 100%;
                }

                .value-number {
                    position: absolute;
                    bottom: 0.5em;
                    right: 1em;

                    p {
                        margin: 0;
                        font-size: $small;
                    }
                }
            }
        }

        .button-choice {
            display: flex;
            gap: 1em;
        }
    }
}

.block-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

h1 {
    margin: 0;
    font-size: $veryBig;
    font-family: $font-avenir-black;
}

p {
    margin: 0;
}
</style>
